import React, { Component, useState} from 'react'
import { connect, useDispatch } from 'react-redux'
import { userService } from '../../services/user.service'
import { validateEmail } from '../../lib/helpers/helpers'
import { Link, Navigate } from 'react-router-dom'
import { AbsoluteCenter, Box, Button, Container, Divider, FormControl, Spinner, FormLabel, Grid, GridItem, Heading, Input, SimpleGrid, Text, useToast, InputLeftElement, InputGroup, Card, CardBody } from '@chakra-ui/react'
import guardarUsuario from '../../redux/actions/guardarUsuario'
import { AiFillGoogleCircle } from 'react-icons/ai'
import { tremus_style } from '../../lib/global/style'

const Login = (props) => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const toast = useToast()
    const dispatch = useDispatch()
    dispatch(guardarUsuario(false))

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        
        if (!email)     return toast({ title: "Email es requerido", status: "error" })
        if (!password)  return toast({ title: "Contraseña es requerida", status: "error" })
        
        if(validateEmail(email) !== true) return toast({
            title: "Email inválido",
            status: "error"
        })
        setLoading(true)
        userService.login(email, password )
            .then(
                user => {
                    if(!user){
                        toast({ title: "Sin datos obtenidos", status: "error" })
                    } else if(user.errorMessage){
                        toast({ title: user.errorMessage, status: "error" })
                    } else if (user.tokenSession) {
                        dispatch(guardarUsuario(user))
                        window.location = '/'
                    }
                    return setLoading(false)
                },
                error => {
                    return setLoading(false)
                }
            )
    }

    const handleChangeEmail = (e) => {
        const { value } = e.target
        setEmail(value)
    }

    const handleChangePassword = (e) => {
        const { value } = e.target
        setPassword(value)
    }

    return <Container maxWidth="md">
        <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  height="100vh" // Puedes ajustar esto según tus necesidades
>
    <Card>
        <CardBody>
        <FormControl onSubmit={handleSubmit}>
            <Heading>WESYNCRO 1.5.4</Heading>
            <Text mb={3} fontSize={14}>Integra · Crece · Evoluciona</Text>

            {/* <img src="https://tremus.cl/wp-content/uploads/2023/02/logo_institucional_tremus.svg" style={{ height: 70, marginBottom: 10 }} /> */}
        <Heading  size='lg' mb={1}>Iniciar sesión</Heading>
        <Heading  size='sm' mb={3}>Comienza a distribuir en canales de venta tus productos.</Heading>
            <FormLabel>Email</FormLabel>
            <Input mb={3} type='email' onChange={handleChangeEmail} />
            <FormLabel>Contraseña</FormLabel>
            <Input mb={3} type='password' onChange={handleChangePassword} />
            { loading ? <Box textAlign="center"><Spinner size="lg" /></Box> : <Button style={{ width: "100%" }} colorScheme={tremus_style.colorButtonPrimary} onClick={() => handleSubmit()} >INICIAR SESIÓN</Button> }
            {/* <Box position='relative' padding='10'>
            <Divider />
            <AbsoluteCenter bg='white' px='4'>O</AbsoluteCenter>
            </Box>
            <Box>
                <Button style={{ width: "100%" }} > <AiFillGoogleCircle style={{ marginRight: 5, color: "red" }} /> GOOGLE</Button>
            </Box> */}
{/* 
            <Box mt={3}>
            <Text textAlign="center" mb={5}>¿Aún no tienes un usuario? <Link to="/register" style={{ fontWeight: 'bold' }}><Text >Registrarme</Text> </Link> </Text>
            </Box> */}
        </FormControl>
        </CardBody>
        </Card>
</Box>
       
    </Container>
}

export default Login