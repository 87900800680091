import { Badge, Box, Button, Card, CardBody, Container, Divider, Flex, Grid, GridItem, Heading, SimpleGrid, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text } from '@chakra-ui/react'
import React from 'react'
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../Header'
import { formatearRUTChileno } from '../../lib/helpers/data/chile'
import { ExternalLinkIcon, RepeatClockIcon } from '@chakra-ui/icons'
import { fechaATexto } from '../../lib/helpers/helpers'
import ListadoEventos from '../Actividad/listado'
import ListadoSolicitudes from '../Solicitudes/listado'
import Structure from '../Structure'
import { Link } from 'react-router-dom'
import { rutas } from '../../lib/global/rutas'

const Home = (props) => {
  const dispatch = useDispatch()
  const { data, tokenSession } = useSelector(state => state.miusuario)
  const empresa = data.empresa

  const linkSolicitudes = <Link to={rutas.solicitudes.slug}><Button size="xs" variant="outline" mb={3}>VER MÁS <ExternalLinkIcon ml={1} /></Button></Link>
  
  const render = () => {
    return <Box>
    <Box bg="linear-gradient(to right, #1061b7, #00b1b1)" p={5}>
    <Grid templateColumns={{ md: "2fr 10fr", base: "1fr" }} gap={4}>
      <GridItem >
        <Box pt={3}>
        <Heading mb={2} lineHeight={8} color="white">{empresa.razonsocial}</Heading>
        <Heading size="md" color="white" >{formatearRUTChileno(empresa.rut)}</Heading>
        <Text lineHeight={8} color="white">{empresa.direccion ? empresa.direccion.toUpperCase() : 'Sin información'}</Text>
        {/* <Button size="xs" variant="outline" colorScheme="whiteAlpha" >GESTIONAR EMPRESA <ExternalLinkIcon ml={1} /></Button> */}
        </Box>
      </GridItem>
      <GridItem >
        <Card mt={3} style={{ marginBottom: -60 }}>
        <CardBody>
        <Heading >Integraciones activas</Heading>
        <Text>En esta sección verás las integraciones activas de tu cuenta</Text>
        <Button size="xs" variant="outline" >VER MÁS <ExternalLinkIcon ml={1} /></Button>
        <Divider mt={4} mb={4} />
        <Heading size="md">Actividad más reciente de tus órdenes</Heading>
        <Divider mt={2} mb={2} />
        <ListadoEventos condicion_default={{ tipo_recurso: "orders" }} />
        </CardBody>
        </Card>
      </GridItem>
    </Grid>
    </Box>

  </Box>
  }

  return <Structure component={render()} />
}

export default Home