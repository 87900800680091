const stringByTypeRequest = (type) => {
    switch (type) {
        case "change_properties":
                return "Cambios en la ficha del producto"
        case "offers":
                return "Oferta"
        case "inventory_change":
                return "Cambios de inventario"
        default:
            return "No idenfiticado"
    }
}

module.exports =  {
    stringByTypeRequest
}