import React, { useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import Login from '../Login';
import Home from '../Home';
import CompletarRegistro from '../Enrolamiento/completar_registro_empresa';
import ValidarEmpresa from '../Enrolamiento/validacion_empresa';
import Registrarme from '../Enrolamiento/registro';
import MiCuenta from '../MiCuenta';
import DetalleProducto from '../Productos/detalle';
import { rutas } from '../../lib/global/rutas';
import Calificar from '../Calificaciones/calificar';
import Integraciones from '../Productos';


const PrivateRoute = (props) => {
    const session = useSelector(state => state.miusuario)

    const outside_session = () => {
        return <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Registrarme /> } />
        <Route path='*' element={<Navigate to="/login" />} />
        <Route path={`${rutas.calificar.slug}/:id`} element={<Calificar />} />
    </Routes>
    }

    // USUARIO NO LOGEADO
    if(!session) return outside_session()

    // COMPLETAR DATOS DE EMPRESA
    const ruta_completar_registro = () => {
        return <Routes>
        <Route path='/redirect/complete/registration' element={<CompletarRegistro />}  />
        <Route path='*' element={<Navigate to="/redirect/complete/registration" />} />
    </Routes>
    }
    
    // VALIDAR REQUISITOS DE EMPRESA
    const ruta_validar_empresa = () => {
        return <Routes>
        <Route path='/redirect/validate/business' element={<ValidarEmpresa />}  />
        <Route path='*' element={<Navigate to="/redirect/validate/business" />} />
    </Routes>
    }

    // USUARIO LOGEADO - DIVERSOS FLUJOS
    const { data } = session
    if(!data) return outside_session()
    // if(!data.empresa) return ruta_completar_registro()
    // if(!["active","pending"].includes(data.empresa.status)) return ruta_validar_empresa()

    return <Routes>
        <Route path={rutas.dashboard.slug} element={<Home/>} />
        <Route path={rutas.cuenta.slug} element={<MiCuenta />} />
        <Route path={rutas.integraciones.slug} element={<Integraciones />} />
        <Route path={`${rutas.integraciones.slug}/:id`} element={<DetalleProducto />} />
        <Route path='*' element={<Navigate to={rutas.dashboard.slug} />} />
    </Routes>
}

export default PrivateRoute;