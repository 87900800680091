import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from '../src/redux/store';
import PrivateRoute from './components/PrivateRoute';
const { store, persistor } = reduxStore();

function App() {
  const session = true
  return (
    <Provider store={store}>
    <PersistGate persistor={persistor}>
    <BrowserRouter>
      <PrivateRoute />
    </BrowserRouter>
    </PersistGate>
    </Provider>
  );
}

export default App;
