const rutas = {
    cuenta: {
        title: "Mi cuenta",
        slug: '/account'
    },
    reports: {
        title: "Reportes",
        slug: '/reports'
    },
    solicitudes: {
        title:"Solicitudes",
        slug: '/requests'
    },
    calificaciones: {
        title:"Calificaciones",
        slug: '/rating'
    },
    products: {
        title:"Productos",
        slug: '/products'
    },
    products_import: {
        title:"Importar productos",
        slug: '/import-products'
    },
    products_import_inventory: {
        title:"Importar inventario",
        slug: '/import-products-inventory'
    },
    dashboard: {
        title:"Inicio",
        slug: '/dashboard'
    },
    orders: {
        title:"Pedidos",
        slug: '/orders'
    },
    calificar: {
        title:"Calificar",
        slug: '/calificar'
    },
    integraciones: {
        title:"Integraciones",
        slug: '/integraciones'
    },
}

module.exports = {
    rutas
}