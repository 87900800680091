const keyToLabelProduct = (key) => {
    switch (key) {
        case "precio":
            return "Precio"
        case "descripcion":
            return "Descripción"
        case "titulo":
            return "Título"
        case "precio_oferta":
            return "Precio oferta"
        case "sku":
            return "Sku"
        case "stock_type":
            return "Tipo de inventario"
        default:
            break;
    }
}

const stringByStatus = (status) => {
    switch (status) {
        case "active":
            return "Activa"
        case "inactive":
            return "Inactiva"
        case "rejected":
            return "Rechazado"
        case "transit":
            return "Enviado"
        case "unpublished":
            return "No publicado"
        case "reviewing":
            return "En revisión"
        default:
            return status
    }
}

const stringByStatusOrder = (status) => {
    switch (status) {
        case "pending":
            return "Pendiente"
        case "prepared":
            return "Preparado"
        case "transit":
            return "Enviado"
        case "delivered":
            return "Recibido"
        case "undelivered":
            return "No recibido"
        default:
            return status
    }
}

module.exports = {
    keyToLabelProduct,
    stringByStatus,
    stringByStatusOrder
}