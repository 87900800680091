import { Badge, Button, Divider, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { fechaATexto } from "../../lib/helpers/helpers"
import { keyToLabelProduct } from "../../lib/helpers/productos"
import { stringByStatusEvento } from "../../lib/helpers/eventos"
import { AiFillStar } from "react-icons/ai"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/global/rutas"
import { MdOutlineSyncAlt } from "react-icons/md";

const BoxListEvento = (props) => {
    const {
        evento,
        showLink
    } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    const visualizarCambios = () => {
        if(!evento) return false

        switch (evento.tipo_solicitud) {
          case "change_properties":
                if(!evento.solicitud) return false
                if(typeof evento.solicitud !== "object" ) return false

                return <div>
                    <Divider mt={3} mb={3} />
                    <Text>Estado de la solicitud: <b>{stringByStatusEvento(evento.solicitud.status_requested)}</b></Text>
                    <Divider mt={3} mb={3} />
                    <Heading size="sm" mb={3}>Cambios solicitados</Heading>
                    {Object.keys(evento.solicitud.valor).map(key => <h4><b>{keyToLabelProduct(key)}</b> {evento.solicitud.valor[key]}</h4>)}
                </div>
          case "inventory_change":
                if(!evento.solicitud) return false
                if(typeof evento.solicitud !== "object" ) return false
                return <div>
                  <Text>{evento.solicitud.descripcion}</Text>
                  { showLink === true ? <Link to={`${rutas.products.slug}/${evento.id_target}`}><Button size="xs" >VER DETALLES DEL PRODUCTO</Button> </Link> : false }
                </div>
          case "rating":
                return <div>
                    <div>{ Array.from({ length: evento.stars }, (_, index) => <AiFillStar style={{ display:"inline" }} color="#ffcb00" />) }</div>
                    { showLink === true ? <Link to={`${rutas.products.slug}/${evento.id_target}`}><Button size="xs" >VER DETALLES DEL PRODUCTO</Button> </Link> : false }
                </div>
          default:
            break;
        }

    }

    const modalDetalles = (datos) => {
        return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ fontSize: 15 }}>{fechaATexto(evento.createdAt)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            
            <Text>Descripción del evento</Text>
          <Heading size="md">{evento.descripcion}</Heading>
          <Divider mt={3} mb={3} />
            {visualizarCambios()}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} size="xs" onClick={onClose}>CERRAR</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    }

    if(!evento) return false

    return <div>
        <span style={{ fontSize: 12 }}>{fechaATexto(evento.createdAt)}</span>
        <Heading size="sm">{evento.titulo}</Heading>
        <Divider  mt={2} mb={2} />
        <Heading size="sm" mb={2}><Badge>{evento.plataforma_origen} <MdOutlineSyncAlt style={{ display: "inline-block" }} />  {evento.plataforma_destino}</Badge></Heading>
        <Link to={`${rutas.integraciones.slug}/${evento._id}`}><Button size="xs">VER DETALLES</Button></Link>
        {modalDetalles()}
    </div>

}

export default BoxListEvento