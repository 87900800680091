import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, Divider, FormLabel, Grid, GridItem, Heading, Input, Select, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { url_images, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import Header from "../Header"
import Cargando from "./cargando"
import { fechaATexto } from "../../lib/helpers/helpers"
import Rating from '@mui/material/Rating';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    // Puedes personalizar el tema aquí.
  });

  
const items_servicios = [
    {
        label: "Tiempo de entrega",
        value: "tiempo_entrega",
    },
    {
        label: "Calidad del producto",
        value: "calidad_producto",
    },
    {
        label: "Experiencia de compra",
        value: "experiencia_compra",
    },
]

const acciones = [
    {
        value: "malo",
        label: "Malo"
    },
    {
        value: "bueno",
        label: "Bueno"
    },
    {
        value: "muy_bueno",
        label: "Muy bueno"
    },
    {
        value: "estupendo",
        label: "Estupendo"
    },
]

const Calificar = (props) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ producto, setProducto ] = useState(false)
    const [ calificado, setCalificado ] = useState(false)
    const [ loadingSending, setLoadingSending ] = useState(false)
    const [ productos, setProductos ] = useState(false)
    const [ loadingActualizacion, setLoadingActualizacion ] = useState(false)
    const [ calificacion, setCalificacion ] = useState({})
    const [ loadingAnulacion, setLoadingAnulacion ] = useState(false)
    const [ productoOriginal, setProductoOriginal ] = useState(false)
    const [ etapas, setEtapas ] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const toast = useToast()

    const enviarComentarios = async () => {
        const validos = productos.filter(p => p.comentario)
        if(validos.length < 1) return toast({ title: "Error", description: "Agrega al menos un comentario a uno de los productos", status: "error" })
        setLoadingSending(true)
        return fetch(`${urlapi}/calificaciones/rate`,{
            method:'POST',
            body: JSON.stringify({
                id: producto._id,
                productos,
                calificacion,
                calificacion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Enviado exitosamente, gracias por tus comentarios", status: "success"})
                setCalificado(true)
            }
            return setLoadingSending(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingSending(false)
        })
    }

    const obtenerDatos = async () => {
        if(!id) return setLoadingMaster(false)
        setLoadingMaster(true)
        return fetch(`${urlapi}/calificaciones/rate?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                setProducto(res)
                const nueva_instancia_objeto = JSON.parse( JSON.stringify(res) )
                if(res) if(res.payload) if(res.payload.line_items) setProductos(res.payload.line_items)
                setProductoOriginal(nueva_instancia_objeto)
            if(res.calificado === true) setCalificado(true)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingMaster(false)
        })
    }
    
    const guardarCambios = async () => {
        
        const requeridos = [
            { value:'titulo', label: 'Título' },
            { value:'sku', label: 'Sku' },
            { value:'descripcion', label: 'Descripción' },
            { value: "precio", label: "Precio" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!producto[campo.value]) faltantes.push(campo.label)
            return true
        })

        if(faltantes.length > 0) return toast({ title: `Faltan campos: ${faltantes.join(', ')}`, status: "error"})

        let cambios = {}
        Object.keys(producto).map(key => {
            const valor_real = producto[key] ? producto[key] : ""
            if(!valor_real && !cambios[key]) return false
            if(productoOriginal[key] !== valor_real) cambios[key] = valor_real
        })
        
        if(Object.keys(cambios).length < 1) return toast({ title: `No realizaste ningún cambio`, status: "error"})

        const payload_enviar = {
            id_target: producto._id,
            valor: cambios,
            descripcion: "Cambios en la ficha del producto",
            type: "change_properties",
            ids_canales: [ "web-tremus" ]
        }

        setLoadingActualizacion(true)
        return fetch(`${urlapi}/productos`,{
            method:'PUT',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Cambios solicitados exitosamente", status: "success" })
                return obtenerDatos()
            }
            return setLoadingActualizacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingActualizacion(false)
        })
    }

    const handleChangeProduct = (e,i) => {
        const { value } = e.target
        productos[i].comentario = value
        setProductos(productos)
    }

    useEffect(() => {
        obtenerDatos()
    }, [])
    

    const detallesProducto = () => {
        if(loadingMaster) return <Cargando/>


        return <div>
            <SimpleGrid spacing={15} columns={4} mb={15}>
            {
                productos.map((pro,i) => {
                    return <Box key={pro.sku} templateColumns={{ md: "3fr 9fr", base: "1fr" }} gap={4} >
                        <Card>
                            <CardBody>
                            <Heading style={{ fontSize:20 }} >{pro.name}</Heading>
                            <p>{pro.sku}</p>
                            <Input placeholder="Escribe tu opinión" onChange={(e) => handleChangeProduct(e,i)} />
                            </CardBody>
                        </Card>
                    </Box>
                })
            }
            </SimpleGrid>
            <Button isLoading={loadingSending} colorScheme='teal' onClick={() => enviarComentarios()} >ENVIAR MIS COMENTARIOS</Button>
        </div>
        
    }



    const calificaionServicios = () => {
        return <div>
            <SimpleGrid columns={3} mb={10}>
            {
                items_servicios.map((item,i) => {
                    return <Box key={`e-${i}`}>
                        <Heading size="md" mb={2}>{item.label}</Heading>
                        <ThemeProvider theme={theme}>
                        <Rating
                            size="large"
                            name="simple-controlled"
                            // value={value}
                            onChange={(event, newValue) => {
                                calificacion[item.value] = newValue
                                return setCalificacion(calificacion)
                            }}
                            />
                        </ThemeProvider>
                    </Box>
                })
            }
            </SimpleGrid>
        </div>
    }

    if(loadingMaster) return <Box style={{ textAlign: "center" }} p={15}>
        <Spinner size="xl" />
    </Box>

    if(calificado) return <Box style={{ textAlign: "center" }} p={15}>
        <img style={{ height: 150, margin: "0 auto"}} src={`${url_images}/illustrations/customer-review.svg`} />
        <Heading >¡Gracias por calificar tu pedido!</Heading>
        </Box>

    return <Box style={{ textAlign: "center" }} p={15}>
        <div style={{ textAlign: "center" }}>
        <Heading >Cuéntanos como te fue con tu pedido {producto.payload.id}</Heading>
        <p style={{ marginBottom: 10 }}>Realizado el {fechaATexto(producto.payload.date_created)}</p>
        <img style={{ height: 150, margin: "0 auto"}} src={`${url_images}/illustrations/customer-review.svg`} />
        <Box p={5} mt={5}>
        <Heading as="h4" size="xl" mb={3}>¿Cómo te fue con la compra?</Heading>
        {calificaionServicios()}


        <Heading as="h4" size="md" mb={3}>Déjanos una pequeña reseña de los productos de tu orden</Heading>
        {detallesProducto()}
        </Box>
        </div>
  </Box>
} 

export default Calificar