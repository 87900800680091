import { Box, Card, CardBody, Grid, GridItem, Heading, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Cargando from "./cargando"
import { cerrarSesion } from "../../redux/actions/sesion"
import { url_images, urlapi } from "../../lib/backend/data"
import { fechaATexto, formatoMoneda, formatoNumero } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import BoxListEvento from "./boxlist"

const ListadoEventos = (props) => {
    const {
        condicion_default,
        showLink,
        reset
    } = props
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ loading, setLoading ] = useState(true)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ filtros, setFiltros] = useState([])
    const [ conductores, setConductores] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ empresa, setEmpresa] = useState(data.empresa)
    const session = useSelector(state => state.miusuario)
    const toast = useToast()
    const module = 'integraciones'

    const obtenerDatos = async (page, query)=>{
        setLoading(true)
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ reset ])

    const visualizarCambios = () => {

    }
    const handleChangePagina = (e) => {
        console.log(e)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            
        </div>
    }

    const pantallaVacia = () => {
        return <Box textAlign="center" p={10} >
        <div style={{ textAlign: "center" }}>
        <Heading as="h2" size="md" >Esta es la bitácora de tu producto, aquí verás todo lo que ocurre con él.</Heading>
        <p>Solicitudes, Cambios realizados, autorizaciones, ofertas probadas y mucho más...</p>
        <img style={{ margin: "0 auto", maxWidth: 300 }} src={`${url_images}/illustrations/product-marketing.svg`} />
       
        </div>
    </Box>
    }

    const mostrarRegistros = () => {
        if(loading) return <Cargando />
        if(conductores.length < 1) return pantallaVacia()

        return <div>
            
                <Grid templateColumns={{ md: "1fr 1fr 1fr 1fr 1fr", base: "1fr" }} gap={4}>
                <Text mb={4}>{formatoNumero(total)} Integraciones registradas</Text>

                {
                    filtros.map((filtro,i) => {
                        return <GridItem key={`f-${i}`}>
                            <Box>
                                    <StatGroup>
                                        <Stat>
                                        <StatLabel>{filtro.label}</StatLabel>
                                        <StatNumber>{formatoNumero(filtro.cantidad)}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                        </Stat>
                                    </StatGroup>
                            </Box>
                            
                        </GridItem>
                    })
                }
            </Grid>
            
                    {
                        conductores.map(producto => {
                            return <Card size="sm" key={`pro-${producto._id}`} mb={3} variant="outline" >
                                <CardBody>
                                    <BoxListEvento showLink={showLink} evento={producto} />
                                </CardBody>
                            </Card>
                        })
                    }
            
        </div>
    }
    return <Box>
    {mostrarRegistros()}
    </Box>
}

export default ListadoEventos