import data from '../lib/backend/data'

export const userService = {
    login,
    logout
};

function login( username, password ) {
    return fetch(`${data.urlapi}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({
            email: username.toLowerCase(),
            password: password
        }),
        headers: {
        'Content-Type':'application/json'
        }
    })
        .then(user => user.json())
        .then(user => user)
        .catch(error => {
            console.log(error)
            return false
        })
}

function logout() {
    // remove user from local storage to log user out
    
}
