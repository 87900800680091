const stringByStatusEvento = (value) => {
    switch (value) {
        case "pending":
            return "Pendiente"
        case "accepted":
            return "Aceptado"
        case "rejected":
            return "Rechazado"
        case "aborted_by_provider":
            return "Abortado por el proveedor"
        default:
            return value
    }
}

module.exports = {
    stringByStatusEvento
}