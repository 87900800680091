import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, Divider, FormLabel, Grid, GridItem, Heading, Input, Select, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import Header from "../Header"
import Cargando from "./cargando"
import { fechaATexto } from "../../lib/helpers/helpers"
import { CheckCircleIcon, TimeIcon } from "@chakra-ui/icons"
import { FaBeer } from 'react-icons/fa';
import { tremus_style } from "../../lib/global/style"
import { FiAlertCircle } from "react-icons/fi";
import ListadoEventos from "../Actividad/listado"
import { keyToLabelProduct, stringByStatus } from "../../lib/helpers/productos"
import ListadoSolicitudes from "../Solicitudes/listado"
import CrearSolicitud from "../Solicitudes/crear"
import Structure from "../Structure"
import { rutas } from "../../lib/global/rutas"
import ListadoCalificaciones from "../Calificaciones/listado"
import { RiChatHistoryLine } from "react-icons/ri"
import { GrDocumentTime } from "react-icons/gr"
import { AiOutlineStar } from "react-icons/ai"
import ListadoPedidos from "../Pedidos/listado"
import ListadoEventosIntegracion from "../Eventos/listado"

const DetalleProducto = (props) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ producto, setProducto ] = useState(false)
    const [ loadingActualizacion, setLoadingActualizacion ] = useState(false)
    const [ loadingAnulacion, setLoadingAnulacion ] = useState(false)
    const [ productoOriginal, setProductoOriginal ] = useState(false)
    const [ etapas, setEtapas ] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const toast = useToast()

    const anularSolicitud = async () => {
        setLoadingAnulacion(true)
        return fetch(`${urlapi}/actividad/solicitudes/anular?id=${producto.cambios_pendientes_ficha._id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "anulado exitosamente", status: "success"})
                obtenerDatos()                
            }
            return setLoadingAnulacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingAnulacion(false)
        })
    }

    const obtenerDatos = async () => {
        if(!id) return setLoadingMaster(false)
        setLoadingMaster(true)
        return fetch(`${urlapi}/integraciones?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                setProducto(res)
                const nueva_instancia_objeto = JSON.parse( JSON.stringify(res) )
                setProductoOriginal(nueva_instancia_objeto)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingMaster(false)
        })
    }
    
    const guardarCambios = async () => {
        
        const requeridos = [
            { value:'titulo', label: 'Título' },
            { value:'sku', label: 'Sku' },
            { value:'descripcion', label: 'Descripción' },
            { value: "precio", label: "Precio" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!producto[campo.value]) faltantes.push(campo.label)
            return true
        })

        if(faltantes.length > 0) return toast({ title: `Faltan campos: ${faltantes.join(', ')}`, status: "error"})

        let cambios = {}
        Object.keys(producto).map(key => {
            const valor_real = producto[key] ? producto[key] : ""
            if(!valor_real && !cambios[key]) return false
            if(productoOriginal[key] !== valor_real) cambios[key] = valor_real
        })
        
        if(Object.keys(cambios).length < 1) return toast({ title: `No realizaste ningún cambio`, status: "error"})

        const payload_enviar = {
            id_target: producto._id,
            valor: cambios,
            descripcion: "Cambios en la ficha del producto",
            type: "change_properties",
            ids_canales: [ "web-tremus" ]
        }

        setLoadingActualizacion(true)
        return fetch(`${urlapi}/productos`,{
            method:'PUT',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Cambios solicitados exitosamente", status: "success" })
                return obtenerDatos()
            }
            return setLoadingActualizacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingActualizacion(false)
        })
    }

    const handleChangeProduct = (e) => {
        const { name, value } = e.target
        if(["precio","precio_oferta"].includes(name)){
            let numero = parseInt(value)
            producto[name] = isNaN(numero) ? '' : numero
        } else {
            producto[name] = value
        }
        setProducto(producto)
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const mostrarEtapas = () => {
        if(!etapas) return false
        if(Array.isArray(etapas) !== true) return false
        if(etapas.length < 1) return false

        return <Box>
            <Grid templateColumns={{ md: "3fr 9fr", base: "1fr" }} gap={4} >
            {
                etapas.map((etapa,i) => {
                    return <Box key={`etapa-${i}`}>
                        { etapa.check === true ? <CheckCircleIcon/> : <TimeIcon /> }
                        <Heading size="md">{etapa.label}</Heading>
                        <Heading size="sm">{fechaATexto(etapa.fecha)}</Heading>
                    </Box>
                })
            }
            </Grid>
        </Box>
    }

    const mostrarAcciones = () => {
        if(producto.cambios_pendientes_ficha) return <Card background={tremus_style.colorButtonPrimary} color="white">
            <CardBody>
                <FiAlertCircle size={30} color="orange" />
                <span style={{ fontSize: 11}}>{fechaATexto(producto.cambios_pendientes_ficha.fecha_hora_local)}</span>
                <Heading size="sm">Solicitaste cambios en la ficha, debes esperar a que sean revisados o anularlos para poder enviar nuevos cambios</Heading>
                <Divider mt={3} mb={3} />
                {Object.keys(producto.cambios_pendientes_ficha.valor).map(key => <h4 style={{ fontSize: 14 }}><b>{keyToLabelProduct(key)}</b> {producto.cambios_pendientes_ficha.valor[key]}</h4>)}
                <Divider mt={3} mb={3} />
                <Button size="xs" isLoading={loadingAnulacion}  colorScheme="red" onClick={() => anularSolicitud()}>ANULAR CAMBIOS</Button>
            </CardBody>
        </Card>

        return <Button  isLoading={loadingActualizacion} colorScheme={tremus_style.colorButtonPrimary} onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
    }

    const detallesProducto = () => {
        if(loadingMaster) return <Cargando/>
        if(!producto) return <Heading>Sin datos</Heading>

        return <Box>
                    <Tabs>
                        <TabList >
                        {
                            producto.codigos_integracion.map((int,i) => {
                                return <Tab key={`int-${i}`} fontSize={12} >{int.titulo ? int.titulo : int.code}</Tab>

                            })
                        }
                        </TabList>
                        <TabPanels>
                        {
                            producto.codigos_integracion.map((int,i) => {
                                return <TabPanel key={`int-${i}`} ><ListadoEventosIntegracion condicion_default={{ code: producto.codigo_integracion, nombre: int.code }} /></TabPanel>

                            })
                        }
                        </TabPanels>
                        </Tabs>
            </Box>
    }

    const BreadCrumb = () => {
        return <div>
            <Breadcrumb>
            <BreadcrumbItem>
                <BreadcrumbLink><Link to={rutas.integraciones.slug}>{rutas.integraciones.title}</Link> </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink >Detalles de integración</BreadcrumbLink>
            </BreadcrumbItem>
            </Breadcrumb>
        </div>
    }

   const render = () => {
    return <Box>
    <Box bg="linear-gradient(to right, #1061b7, #00b1b1)" p={5}>
    
    <Grid templateColumns={{ md: "12fr", base: "1fr" }} gap={4}>
      <GridItem >
        <Card mt={3} style={{ marginBottom: -60 }}>
        <CardBody>
        <BreadCrumb />
        <Heading >{producto ? producto.titulo : "Detalles de integración"}</Heading>
        <Heading size="md" >{stringByStatus(producto.estado)}</Heading>
        </CardBody>
        </Card>
      </GridItem>
    </Grid>
    </Box>
    <Box p={5} mt={10}>
    
    {detallesProducto()}
    
    </Box>
  </Box>
   }

return <Structure component={render()} />
} 

export default DetalleProducto