const validarRUTChileno = (rut) => {
    // Eliminar puntos y guión del RUT (dejar solo dígitos y K)
    const rutLimpio = rut.replace(/[.-]/g, "").toUpperCase();
  
    if (!/^[0-9]+[0-9K]?$/.test(rutLimpio)) {
      // El RUT contiene caracteres inválidos
      return false;
    }
  
    const rutSinDigitoVerificador = rutLimpio.slice(0, -1);
    const digitoVerificador = rutLimpio.slice(-1);
  
    let suma = 0;
    let multiplicador = 2;
  
    // Calcular la suma ponderada de los dígitos del RUT
    for (let i = rutSinDigitoVerificador.length - 1; i >= 0; i--) {
      suma += parseInt(rutSinDigitoVerificador.charAt(i), 10) * multiplicador;
      multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
    }
  
    const digitoCalculado = 11 - (suma % 11);
    const digitoVerificadorEsK = digitoVerificador === "K";
  
    if (digitoCalculado === 11) {
      return digitoVerificadorEsK;
    } else if (digitoCalculado === 10) {
      return digitoVerificador === "0";
    } else {
      return parseInt(digitoVerificador, 10) === digitoCalculado;
    }
  };

  const formatearRUTChileno = (rut) => {
   /// Eliminar puntos y guión del RUT (dejar solo dígitos y K)
  const rutLimpio = rut.replace(/[.-]/g, "").toUpperCase();

  if (!/^[0-9]+[0-9K]?$/.test(rutLimpio)) {
    // El RUT contiene caracteres inválidos
    return rut;
  }

  const rutSinDigitoVerificador = rutLimpio.slice(0, -1);
  const digitoVerificador = rutLimpio.slice(-1);

  let rutFormateado = "";
  let contador = 0;

  // Agregar los puntos al RUT, evitando número negativo en el primer dígito
  for (let i = rutSinDigitoVerificador.length - 1; i >= 0; i--) {
    rutFormateado = rutSinDigitoVerificador.charAt(i) + rutFormateado;
    contador++;
    if (contador % 3 === 0 && i !== 0) {
      rutFormateado = `.${rutFormateado}`;
    }
  }

  // Agregar el guión y el dígito verificador si el RUT tiene dos caracteres o más
  if (rutFormateado.length > 1) {
    rutFormateado += "-";
  }
  rutFormateado += digitoVerificador;

  return rutFormateado;
  };
  
  const limpiarRUTChileno = (rut) => {
    // Eliminar puntos y guión del RUT (dejar solo dígitos y K)
    const rutLimpio = rut.replace(/[.-]/g, "").toUpperCase();
  
    return rutLimpio;
  };

  module.exports = {
    validarRUTChileno,
    limpiarRUTChileno,
    formatearRUTChileno
  }