import { Badge, Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Divider, FormLabel, Heading, Input, Select, SimpleGrid, Skeleton, Spinner, Stack, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { validarRUTChileno } from "../../lib/helpers/data/chile";
import { config_s3, urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import S3FileUpload from 'react-s3';
import { fechaATextoSimple } from "../../lib/helpers/helpers";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ValidarEmpresa = (props) => {
    const [ empresa, setEmpresa ] = useState({ razon_social: "tu empresa"})
    const [ loading, setLoading ] = useState(true)
    const [ loadingReq, setLoadingReq ] = useState(false)
    const [ loadingValidacion, setLoadingValidacion ] = useState(false)
    const [ requisitos, setRequisitos ] = useState([])
    const session = useSelector(state => state.miusuario)
    const toast = useToast()
    const dispatch = useDispatch()

    useEffect(() => {
        if(session.data.empresa){
            console.log(session.data.empresa)
            if(typeof session.data.empresa === "object") setEmpresa({...{}, ...session.data.empresa})
        }
        validarEmpresa()
    }, [])

    const validarEmpresa = async (e) => {
        setLoading(true)
        const url = `${urlapi}/empresas/validar`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res.empresa){

                // VALIDAR SI LA EMPRESA FUE APROBADA
                if(res.empresa.status === "active"){
                    let copia_session = JSON.parse( JSON.stringify(session) )
                    copia_session.data.empresa = res.empresa
                    dispatch(guardarUsuario(copia_session))
                    return window.location = '/dashboard'
                }

                // DEFINIR REQUISITOS
                if(res.requisitos){
                    if(Array.isArray(res.requisitos) !== false){
                        setRequisitos(res.requisitos)
                    }
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoading(false)
        })
    }

    const handleSubmit = () => {
        setLoadingValidacion(true)
        const url = `${urlapi}/empresas/validar`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res.empresa){
                if(!["active","pending"].includes(res.empresa.status)){
                    toast({ title: "Tu empresa aún debe validarse, asegúrate de haber cargado todos los requisitos", status: "error" })
                } else {
                    let copia_session = JSON.parse( JSON.stringify(session) )
                    copia_session.data.empresa = res.empresa
                    dispatch(guardarUsuario(copia_session))
                    return window.location = "/dashboard"
                }
            }
            return setLoadingValidacion(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoadingValidacion(false)
        })
    }

    const subirArchivo = async (req, valor) => {
        const payload = {
            id_requisito: req._id,
            valor
        }
        const url = `${urlapi}/empresas/requisito`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return false
        })
    }

    const handleChangeArchivo = async (e, req) => {

        setLoadingReq(req._id)
        const archivo = e.target.files[0]
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            toast({ title: 'No pudimos cargar tu imagen', status: "error"})
            return setLoadingReq(false)
        }
        const cargado = await subirArchivo(req, data.location)
        if(cargado){
            const pos = requisitos.findIndex(re => re._id ===  req._id)
            requisitos[pos].valor = cargado
            setRequisitos([...[], ...requisitos])
        }
            return setLoadingReq(false)
        })
        .catch(err => {
            console.log(err)
            setLoadingReq(false)
            return toast({ title: 'No pudimos cargar tu imagen', status:"error"})
        })
    }

    const visualizarRequisitos = () => {
        if(loading) return <Stack mt={5}>
            <Skeleton height='40px' mb={2} />
            <Skeleton height='40px' mb={2} />
            <Skeleton height='40px' mb={2} />
        </Stack>

        return <div>
            {
                requisitos.map((req,i) => {
                    return <Box key={req._id} mb={5}>
                      { req.titulo ? <Heading size="md">{req.titulo}</Heading> : false }
                      { req.valor ? <Badge colorScheme="green" >Cargado {fechaATextoSimple(req.valor.fecha)}</Badge> : <Badge colorScheme='purple'>No has cargado ningún archivo</Badge> }
                      { req.descripcion ? <Text>{req.descripcion}</Text> : false }
                      <Box mt={3} mb={3}>
                        { loadingReq === req._id ? <Spinner /> : <input type="file" onChange={(e) => handleChangeArchivo(e, req)} /> }
                      </Box>
                        { (requisitos.length === i+1) ? false : <Divider /> }
                  </Box>
                })
            }
            <Button isLoading={loadingValidacion} style={{ width: "100%" }} colorScheme="blue" onClick={() => handleSubmit()} >CONTINUAR</Button>
        </div>
    }

    return <div>
        <Container maxWidth="lg">
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Puedes ajustar esto según tus necesidades
        >
        <Card w="100">
            <CardHeader pb={0}>
                <Heading size='md'>
                <Heading  size='lg'>Carga la documentación de {empresa.razon_social}</Heading>
                </Heading>
            </CardHeader>

            <CardBody>
                        <Text mb={5}>Sube los requisitos y valida tu empresa</Text>
                        {visualizarRequisitos()}
            </CardBody>
        </Card>
    </Box>
    </Container>
    </div>
}

export default ValidarEmpresa