import { Badge, Box, Button, Divider, GridItem, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react"
import { fechaATexto, fechaUTCATexto } from "../../lib/helpers/helpers"
import { keyToLabelProduct } from "../../lib/helpers/productos"
import { stringByStatusEvento } from "../../lib/helpers/eventos"
import { AiFillStar } from "react-icons/ai"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/global/rutas"
import JsonView from '@uiw/react-json-view';

const BoxListEvento = (props) => {
    const {
        evento,
        showLink
    } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    const visualizarCambios = () => {
        if(!evento) return false

        switch (evento.tipo_solicitud) {
          case "change_properties":
                if(!evento.solicitud) return false
                if(typeof evento.solicitud !== "object" ) return false

                return <div>
                    <Divider mt={3} mb={3} />
                    <Text>Estado de la solicitud: <b>{stringByStatusEvento(evento.solicitud.status_requested)}</b></Text>
                    <Divider mt={3} mb={3} />
                    <Heading size="sm" mb={3}>Cambios solicitados</Heading>
                    {Object.keys(evento.solicitud.valor).map(key => <h4><b>{keyToLabelProduct(key)}</b> {evento.solicitud.valor[key]}</h4>)}
                </div>
          case "inventory_change":
                if(!evento.solicitud) return false
                if(typeof evento.solicitud !== "object" ) return false
                return <div>
                  <Text>{evento.solicitud.descripcion}</Text>
                  { showLink === true ? <Link to={`${rutas.products.slug}/${evento.id_target}`}><Button size="xs" >VER DETALLES DEL PRODUCTO</Button> </Link> : false }
                </div>
          case "rating":
                return <div>
                    <div>{ Array.from({ length: evento.stars }, (_, index) => <AiFillStar style={{ display:"inline" }} color="#ffcb00" />) }</div>
                    { showLink === true ? <Link to={`${rutas.products.slug}/${evento.id_target}`}><Button size="xs" >VER DETALLES DEL PRODUCTO</Button> </Link> : false }
                </div>
          default:
            break;
        }

    }

    const modalDetalles = (datos) => {
        return <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ fontSize: 15 }}>{fechaATexto(evento.createdAt)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            
            <Text>Descripción del evento</Text>
          <Heading size="md">{evento.descripcion}</Heading>
          <Heading size="sm">{evento.nombre} <Badge color="white" backgroundColor={ evento.estado >= 200 && evento.estado < 300 ? "green.300" : "darkgray" }>{evento.estado}</Badge></Heading>
          <Divider mt={3} mb={3} />
          <SimpleGrid columns={2}>
            <Box>
              <Heading as="h2" fontSize={20} >Información enviada</Heading>
              <Divider mb={4} mt={4} />
              <JsonView value={evento.body ? evento.body : {}} /></Box>
            <Box>
              <Heading as="h2" fontSize={20} >Información recibida</Heading>
              <Divider mb={4} mt={4} />
              <JsonView value={evento.respuesta ? evento.respuesta : {}} /></Box>
          </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} size="xs" onClick={onClose}>CERRAR</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    }

    if(!evento) return false

    return <div style={{ textAlign: "left" }}>
        <span style={{ fontSize: 12 }}>{fechaUTCATexto(evento.createdAt)}</span>
        <Heading size="sm">{evento.nombre} <Badge color="white" backgroundColor={ evento.estado >= 200 && evento.estado < 300 ? "green.300" : "darkgray" }>{evento.estado}</Badge></Heading>
        <Text fontSize={13}>{evento.descripcion}</Text>
        <Heading size="sm" mb={2}><Badge p={"2px 10px"} fontSize={11} backgroundColor="darkgray" color="white">{evento.url}</Badge></Heading>
        <Divider  mt={2} mb={2} />
        <Button size="xs" onClick={onOpen} >VER DETALLES</Button>
        {modalDetalles()}
    </div>

}

export default BoxListEvento