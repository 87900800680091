import { Box, Grid, GridItem, Skeleton } from "@chakra-ui/react"

const Cargando = () => {
    return <Box>
        <Grid templateColumns={{ md: "2fr 10fr", base: "1fr" }} gap={4} >
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        </Grid>
    </Box>
}

export default Cargando