import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormLabel, Input, Select, SimpleGrid, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { urlapi } from "../../lib/backend/data"
import { tremus_style } from "../../lib/global/style"
import { stringByTypeRequest } from "../../lib/helpers/solicitudes"
import { FiPlusCircle } from 'react-icons/fi'

const CrearSolicitud = (props) => {
    const { 
        id_target,
        tipo_recurso
    } = props
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ descripcion, setDescripcion ] = useState("")
    const [ valor, setValor ] = useState("")
    const [ requestType, setRequestType ] = useState('')
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const toast = useToast()
    const dispatch = useDispatch()

    const crearNuevaSolicitud = async () => {
        let nuevo_valor = valor
        if(!valor) return toast({ title: "Escoge un valor", status: "error"})
        if(!isNaN( parseInt(valor) )){
            const numero = parseInt(valor)
            if(!numero || numero < 1) return toast({ title: "Escoge un valor", status: "error"})
            nuevo_valor = numero
        }
        const enviar = {
            id_target,
            valor: {
                valor: nuevo_valor
            },
            tipo_recurso,
            descripcion,
            type: requestType,
            ids_canales: ["web-tremus"],
        }
        setLoadingCreacion(true)
        return fetch(`${urlapi}/actividad/solicitudes`,{
            method:'POST',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast({ title: "Sin datos", status: "error"})
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Creado exitosamente", status: "success" })
                onClose()
                if(props.onCreateNew) props.onCreateNew()
                setValor('')
                setDescripcion('')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast({ title: "Error al consultar la información, intente nuevamente", status: "error"})
            return setLoadingCreacion(false)
        })

    }

    const handleChangeDesc = (e) => {
        const { value } = e.target
        setDescripcion(value)
    }

    const handleChangeRequestType = (e) => {
        const { value } = e.target
        setRequestType(value)
        setValor("")
    }

    const handleChangeProduct = (e) => {
        const { name, value } = e.target
        return setValor(value)
    }

    const formularioPorTipoSolicitud = () => {
        if(requestType === "offers") return <Box>
            <Box>
                <FormLabel>Precio oferta</FormLabel>
                <Input name="precio_oferta" min={0} type="number" value={valor} onChange={handleChangeProduct} />
            </Box>
        </Box>

        if(requestType === "inventory_change") return <Box>
            <Box>
                <Text mb={5}>La siguiente cantidad de inventario quedará como la cantidad de inventario final de este producto</Text>
                <FormLabel>Cantidad de inventario final</FormLabel>
                <Input name="precio_oferta" min={0} type="number" value={valor} onChange={handleChangeProduct} />
            </Box>
        </Box>
    }

    return <div>
    <Button size="sm" mb={3} colorScheme={tremus_style.colorButtonPrimary} ref={btnRef} onClick={onOpen}><FiPlusCircle style={{ marginRight: 5 }} /> CREAR SOLICITUD</Button>
    <Drawer
    isOpen={isOpen}
    size="lg"
    placement='right'
    finalFocusRef={btnRef}>
    <DrawerOverlay />
    <DrawerContent >
      <DrawerHeader>Crear un producto</DrawerHeader>
        <DrawerBody>
      <SimpleGrid columns={1} spacing={5}>
        
            <Box>
                <FormLabel>Descripción</FormLabel>
                <Input  value={descripcion} onChange={handleChangeDesc} />
            </Box>
        <Box>
            <FormLabel>Tipo de solicitud</FormLabel>
            <Select name="stock_type" value={requestType} placeholder='Selecciona una opción' onChange={handleChangeRequestType}>
                <option value="offers">{stringByTypeRequest("offers")}</option>
                <option value="inventory_change">{stringByTypeRequest("inventory_change")}</option>
            </Select>
        </Box>
        
        <Box>
        
        {formularioPorTipoSolicitud()}
        </Box>
      </SimpleGrid>            
      </DrawerBody>

      <DrawerFooter>
        <Button variant='outline' mr={3} onClick={onClose}>
          CANCELAR
        </Button>
        <Button isLoading={loadingCreacion} colorScheme={tremus_style.colorButtonPrimary} onClick={() => crearNuevaSolicitud()} >CREAR</Button>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
</div>
}

export default CrearSolicitud