import { Badge, Box, Button, Card, CardBody, Container, Divider, Flex, FormLabel, Grid, GridItem, Heading, Input, SimpleGrid, Skeleton, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../Header'
import { formatearRUTChileno } from '../../lib/helpers/data/chile'
import { ExternalLinkIcon, LockIcon, RepeatClockIcon, StarIcon } from '@chakra-ui/icons'
import { urlapi } from '../../lib/backend/data'
import { tremus_style } from '../../lib/global/style'
import { stringByStatus } from '../../lib/helpers/empresa/empresas'
import Structure from '../Structure'

const MiCuenta = (props) => {
    const dispatch = useDispatch()
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ loading, setLoading ] = useState(true)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ loadingFormPass, setLoadingFormPass ] = useState(false)
    const [ usuario, setUsuario] = useState(false)
    const [ empresa, setEmpresa] = useState(data.empresa)
    const session = useSelector(state => state.miusuario)
    const toast = useToast()

    useEffect(() => {
        obtenerDatos()
    }, [])

    const obtenerDatos = async (e) => {
        setLoading(true)
        const url = `${urlapi}/auth/cuenta`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                setUsuario(res)
                if(res.empresa){
                    setEmpresa(res.empresa)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoading(false)
        })
    }
    
    const guardarCambios = async (e) => {
        setLoadingForm(true)
        const url = `${urlapi}/auth/cuenta`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res._id){
                toast({ title: "Actualizado exitosamente", status: "success" })
            }
            return setLoadingForm(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoadingForm(false)
        })
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }


    const mostrarInfo = () => {
    if(loading) return <Box p={5} mt={10}>
        <Grid templateColumns={{ md: "2fr 10fr", base: "1fr" }} gap={4} >

        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        <GridItem ><Skeleton height={20} mb={3} /></GridItem>
        </Grid>
    </Box>

    if(!usuario) return <Box p={5} mt={10}>
        <Heading mb={2}>Sin datos para mostrar esta sección</Heading>
    </Box>

    return <Box p={5} mt={10}>
    <Grid templateColumns={{ md: "2fr 10fr", base: "1fr" }} gap={4} >
      <GridItem >
        <Box pt={3}>
          <Heading mb={2} size="lg" lineHeight={8}>{empresa.razonsocial}</Heading>
        <Heading size="sm" >{formatearRUTChileno(empresa.rut)}</Heading>
        <Text lineHeight={8} >{empresa.direccion ? empresa.direccion.toUpperCase() : 'Sin información'}</Text>

        <Button size="xs" variant="outline" >{stringByStatus(empresa.status)}</Button>
        </Box>
      </GridItem>
      <GridItem >
        <Card mt={3} >
        <CardBody>
            <StarIcon w={6} />
        <Heading >Datos de mi cuenta</Heading>
        <Text>En esta sección verás tus datos personales y de seguridad</Text>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={5} spacing={5}>
          <Box>
            <FormLabel>Nombres</FormLabel>
            <Input mb={3} variant="filled" name="nombres" defaultValue={usuario.nombres} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Apellidos</FormLabel>
            <Input mb={3} variant="filled" name="apellidos" defaultValue={usuario.apellidos} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Email</FormLabel>
            <Input mb={3} variant="filled" name="email" defaultValue={usuario.email} onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Móvil</FormLabel>
            <Input mb={3} variant="filled" name="movil" defaultValue={usuario.movil} onChange={handleChangeUsuario} />
          </Box>
        </SimpleGrid>
        <Button isLoading={loadingForm} colorScheme={tremus_style.colorButtonPrimary} onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
        </CardBody>
        </Card>

        {/* <Card mt={3} >
        <CardBody>
        <LockIcon w={6} />
        <Heading > Datos de seguridad</Heading>
        <Text>En esta sección podrás cambiar tu contraseña</Text>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={4} spacing={5}>
          <Box>
            <FormLabel>Contraseña</FormLabel>
            <Input mb={3} variant="filled" name="password" type="password" onChange={handleChangeUsuario} />
          </Box>
          <Box>
            <FormLabel>Repite la contraseña</FormLabel>
            <Input mb={3} variant="filled" name="password_repeat" type="password" onChange={handleChangeUsuario} />
          </Box>
        </SimpleGrid>
        <Button isLoading={loadingFormPass} colorScheme={tremus_style.colorButtonPrimary} onClick={() => guardarCambios()} >CAMBIAR CONTRASEÑA</Button>
        </CardBody>
        </Card> */}
      </GridItem>
    </Grid>
    </Box>
  }

  const render = () => {
    return <Box>
    <Box bg="linear-gradient(to right, #1061b7, #00b1b1)" p={5}>
    
    <Grid templateColumns={{ md: "12fr", base: "1fr" }} gap={4}>
      <GridItem >
        <Card mt={3} style={{ marginBottom: -60 }}>
        <CardBody>
        <Heading >Mi cuenta</Heading>
        
        </CardBody>
        </Card>
      </GridItem>
    </Grid>
    </Box>

    {mostrarInfo()}
  </Box>
  }

return <Structure component={render()} />
}

export default MiCuenta