const { DateTime } = require("luxon")
const { configuracion_fechas } = require("./dates")

const control = {}
const formatearMinutos = (value) => {
  if(!isNaN(parseInt(value))){
    if(parseInt(value) < 10) return `0${value}`
  }
  return value
}
const agregarCero = (value) => {
  if(value < 10) return `0${value}`
  return value
}

control.fechaATexto = (fecha, lang) => {
  if(!fecha) return ''
  const date = new Date(fecha)
  const date2 = DateTime.fromISO(fecha).toUTC()
  return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${formatearMinutos(date2.hour)}:${formatearMinutos(date2.minute)} Hrs.`
}

control.fechaATextoSimple = (fecha, lang) => {
    const date2 = DateTime.fromISO(fecha.substring(0,23)).toJSDate()
    const date = new Date(date2)
    return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}

control.validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

control.ordenarProductos = (registros) => {
  const nuevos = registros.sort((a,b)=> {
    const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
    return posicion > b.orden ? 1 : -1
  })
  return nuevos
}

control.fechaUTCATexto = (fecha, lang) => {
  if(!fecha) return ''
  const date = new Date(fecha)
  const date2 = DateTime.fromISO(fecha)
  return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date2.hour}:${formatearMinutos(date2.minute)} Hrs.`
}

control.limpiarRut = (rut) => {
    if(!rut) return rut
    const dato = rut.toString().replace('.','')
    if(!dato) return rut
    const dato2 = dato.replace('.','')
    if(!dato2) return dato
    const dato3 = dato2.replace('.','')
    if(!dato3) return dato2
    return dato3
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

control.corregirTelefono = (num) => {

    if(!num){
      return false
    }

    const digito = num.toString().replace(/\s/g, '')

    const longitud = digito.length

    if(longitud === 0){
      return false
    }
    const numero = digito.replace('+56', '56')
    if(longitud === 9){
      return `56${numero}`
    } else if(longitud === 8){
      return `569${numero}`
    }

    return numero

}

control.formatoMoneda = (value) => {
  if(!value) return 0
  return `$${Intl.NumberFormat("en-DE").format(Math.round(value))}`
}

control.formatoNumero = (value) => {
  if(!value) return 0
  return Intl.NumberFormat("en-DE").format(Math.round(value))
}

module.exports = control