import { Box, Button, Card, CardBody, CardHeader, Container, FormLabel, Heading, Input, InputGroup, InputRightElement, Select, SimpleGrid, Text, useToast } from "@chakra-ui/react"
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles";
import { Link } from "react-router-dom";
import { urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import { validateEmail } from "../../lib/helpers/helpers";
import { tremus_style } from "../../lib/global/style";

const Registrarme = (props) => {
    const [ usuario, setUsuario ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const toast = useToast()
    const dispatch = useDispatch()

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const registrarme = async () => {
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'email', label: 'Email' },
            { value:'movil', label: 'Móvil' },
            { value:'password', label: 'Contraseña' },
            { value:'repeat_password', label: 'Contraseña' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast({ title:`Faltan campos: ${faltantes.join(', ')}`, status: "error" })  
        if(!validateEmail(usuario.email)) return toast({ title:`Email inválido`, status: "error" })  
        if(usuario.password !== usuario.repeat_password) return toast({ title:`Contraseñas no coinciden`, status: "error" })  
        
        if(usuario.movil.length !== 11) return toast({ title:`Móvil inválido, ejemplo correcto: 56912345678`, status: "error" })  
        setLoading(true)
        const url = `${urlapi}/auth/register`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json"
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast({ title: "Sin datos obtenidos", status: "error" })
            } else if(res.errorMessage){
                toast({ title: res.errorMessage, status: "error"})
            } else if(res.data){
                dispatch(guardarUsuario(res))
                return window.location = '/'
            }
            return setLoading(false)
        })
        .catch(error => {
            toast({ title: "No se pudo efectuar la operación", status: "error" })
            return setLoading(false)
        })
    }

    return <div>
        <Container maxWidth="lg">
        <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  height="100vh" // Puedes ajustar esto según tus necesidades
>
<Card w="100">
  <CardHeader pb={0}>
    <Heading size='md'>
    <Heading  size='lg' >Datos personales</Heading>
    </Heading>
  </CardHeader>

  <CardBody>
            <Text>Completar los siguientes datos</Text>
            <SimpleGrid columns={2} spacing={5}>
                <Box>
                <FormLabel>Nombres</FormLabel>
                <Input mb={3} variant="filled" name="nombres" onChange={handleChangeUsuario} />
                </Box>
                <Box>
                <FormLabel>Apellidos</FormLabel>
                <Input mb={3} variant="filled" name="apellidos" onChange={handleChangeUsuario} />
                </Box>
            </SimpleGrid>
            <SimpleGrid columns={1} spacing={5}>
                <Box>
                <FormLabel>Email</FormLabel>
                <Input mb={3} variant="filled" name="email" onChange={handleChangeUsuario} />
                </Box>
            </SimpleGrid>
            <SimpleGrid columns={1} spacing={5}>
                <Box>
                    <FormLabel>Móvil</FormLabel>
                    <Box>
                            <Input mb={3} variant="filled" name="movil" onChange={handleChangeUsuario}  />
                        </Box>
                <SimpleGrid columns={1} spacing={5}>
                <Box>
                <FormLabel>Contraseña</FormLabel>
                <InputGroup size='md' mb={3}>
                <Input
                    pr='4.5rem'
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    name="password"
                    autoComplete="off"
                    onChange={handleChangeUsuario}
                />
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => setShowPassword( showPassword ? false : true )}>
                    {showPassword ? 'OCULTAR' : 'VER'}
                    </Button>
                </InputRightElement>
                </InputGroup>
                <InputGroup size='md' >
                <Input
                    pr='4.5rem'
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Repetir Contraseña'
                    name="repeat_password"
                    onChange={handleChangeUsuario}
                />
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => setShowPassword( showPassword ? false : true )}>
                    {showPassword ? 'OCULTAR' : 'VER'}
                    </Button>
                </InputRightElement>
                </InputGroup>
                </Box>
            </SimpleGrid>
                </Box>
            </SimpleGrid>
            <Button isLoading={loading} style={{ width: "100%" }} mt={3} colorScheme={tremus_style.colorButtonPrimary} onClick={() => registrarme()} >REGISTRARME</Button>
            <Box mt={3}>
            <Text textAlign="center" mb={3} mt={5}>Ya tengo una cuenta <Link to="/login" style={{ fontWeight: 'bold' }}><Text >Iniciar sesión</Text> </Link> </Text>
            </Box>

    </CardBody>
    </Card>

</Box>
        </Container>
    </div>
}

export default Registrarme