const stringByStatus = (status) => {
    switch (status) {
        case "pending":
            return "PENDIENTE DE VALIDACIÓN"
        case "suspended":
            return "SUSCRIPCIÓN PAUSADA"
        case "active":
            return "ACTIVA"
        case "inactive":
            return "INACTIVA"
        default:
            return status
    }
}

module.exports = {
    stringByStatus
}